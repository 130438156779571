<template>
  <v-container class="profile">
    <v-row fluid v-if="userProfile">
      <v-col cols="3" class="mt-3 profile__avatar">
        <user-avatar :avatar="userProfile.avatar" :size="avatarSize" class="mr-3" />

        <div class="profile__actions--mobile" v-if="!isUserLogged">
          <ProfileActions
            :userProfile="userProfile"
            :is-connected="isContactConnected"
            :show-calendar="hasAvailableAppointmentSlots"
            @remove-connection="removeContact"
            @add-connection="addContact"
            @chat="openChat"
            @calendar="openCalendar"
          />
        </div>
      </v-col>

      <v-col cols="9" class="profile__data">
        <div class="profile__actions--desktop" v-if="!isUserLogged">
          <ProfileActions
            :userProfile="userProfile"
            :is-connected="isContactConnected"
            :show-calendar="hasAvailableAppointmentSlots"
            @remove-connection="removeContact"
            @add-connection="addContact"
            @chat="openChat"
            @calendar="openCalendar"
          />
        </div>

        <h1 class="data__name">{{ userProfile.firstName }} {{ userProfile.lastName }}</h1>
      </v-col>
    </v-row>

    <div
      class="mb-0 mt-4 profile__about black--text ql-editor"
      v-html="userProfile.about"
      v-if="userProfile && userProfile.about"
    ></div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ProfileActions from '@/components/profile-actions/ProfileActions.vue';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';

import { toUserBooking } from '@/navigation';

import {
  PUBLIC_PROFILE_MODULE,
  GET_PUBLIC_PROFILE_BY_ID,
} from '@/stores/umanize-app/actions/public-profile/public-profile.actions';
import {
  ADD_TO_CONTACTS,
  GET_RELATIONS,
  RELATIONS_MODULE,
  REMOVE_FROM_CONTACTS,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import {
  APPOINTMENT_MODULE,
  GET_APPOINTMENTS_SLOT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'PublicProfile',
  components: {
    ProfileActions,
    UserAvatar,
  },
  computed: {
    ...mapGetters(RELATIONS_MODULE, ['isConnected']),
    ...mapGetters(PUBLIC_PROFILE_MODULE, ['publicProfileByUserId']),
    ...mapGetters(APPOINTMENT_MODULE, ['hasAvailableAppointmentSlots']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    userId() {
      return this.$route.params.userId;
    },
    eventId() {
      return this.$route.params.eventId;
    },
    avatarSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '150px' : '230px';
    },
    userProfile() {
      return this.publicProfileByUserId(this.userId);
    },
    isContactConnected() {
      return this.isConnected(this.userId);
    },
    isUserLogged() {
      return this.userId === this.loggedInUser.id;
    },
  },
  methods: {
    ...mapActions(PUBLIC_PROFILE_MODULE, [GET_PUBLIC_PROFILE_BY_ID]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS, ADD_TO_CONTACTS, REMOVE_FROM_CONTACTS]),
    ...mapActions(APPOINTMENT_MODULE, [GET_APPOINTMENTS_SLOT]),
    removeContact() {
      this[REMOVE_FROM_CONTACTS](this.userProfile);
    },
    addContact() {
      this[ADD_TO_CONTACTS](this.userProfile);
    },
    openChat(user) {
      this[OPEN_CHAT]({ userIds: [user.id] });
    },
    openCalendar() {
      toUserBooking(this.$router)({
        eventId: this.eventId,
        userId: this.userId,
      });
    },
    async initProfile() {
      await this[GET_PUBLIC_PROFILE_BY_ID](this.userId);
      await this[GET_RELATIONS]();

      if (this.eventId) {
        await this[GET_APPOINTMENTS_SLOT]({
          eventId: this.eventId,
          userId: this.userId,
        });
      }
    },
  },
  mounted() {
    this.initProfile();
  },
  watch: {
    $route() {
      this.initProfile();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.profile {
  padding: $padding-large;

  &__avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0;
  }

  &__data {
    .data__name {
      font-size: $x-large-font-size;
      margin-left: -3px;
    }
  }

  &__actions {
    margin-left: -16px;

    &--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }
  }

  .about-container {
    display: flex;
    align-content: center;
  }

  &__about {
    text-align: justify;
    white-space: pre-line;
  }

  & .ql-editor {
    ::v-deep img {
      max-width: 100%;
    }
  }
}

@include breakpoint(small) {
  .profile {
    padding: $padding-x-large 20%;

    &__avatar {
      flex-direction: row;
      justify-content: flex-end;
    }

    &__data {
      padding-left: $padding-large;

      .data__name {
        font-size: $xxx-large-font-size;
      }
    }

    &__actions {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
      }
    }
  }
}
</style>
